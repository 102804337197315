<template>
  <div class="flex-grow-1">
    <UserTitle>
      <template v-slot:actions>
          <div>
            <h2 class="text-h4 text-sm-h3 font-weight-black">School Profile</h2>
          </div>
      </template>
    </UserTitle>

    <!-- Filters -->
    <div>
      <v-row>
        <v-col cols="12" class="job-section"> 
          <div>
            <!-- Title & Description Card -->
            <v-card 
              v-if="!isValid"
              class="mb-6 shadow-lg animate__animated animate__fadeIn"
            >
              <v-card-text class="px-10">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="mb-2" :class="titleFont">School Overview</div>
                        <v-sheet max-width="650">
                          <p class="body-1">Answer a few questions about your school to help attract the right candidates to your roles. This will be visible to educators seeking contracts.</p>
                        </v-sheet>
                        <v-divider class="my-6"></v-divider>
                        <v-form ref="overview" v-if="user">
                            <school-logo 
                              v-if="school.logo && (typeof school.logo !== 'object')" 
                              @delete="confirmDeleteLogo()"
                              :filename="school.logo" 
                              width="150"
                              editable
                            />

                            <div v-if="!school.logo || (typeof school.logo == 'object')">
                              <span :class="labelFont">Logo</span>
                              
                              <v-file-input
                                v-model="school.logo"
                                placeholder="Select Photo *"
                                prepend-icon=""
                                prepend-inner-icon="mdi-image"
                                class="mt-4"
                                accept="image/*"
                                ref="photoInput"
                                show-size
                                :rules="[rules.required]"
                                outlined
                              ></v-file-input>
                            </div>

                            <span :class="labelFont">School Name</span>
                            
                            <v-text-field
                              v-model="school.name"
                              :rules="[rules.required]"
                              label="School Name *"
                              class="mt-3"
                              outlined
                            ></v-text-field>

                            <span :class="labelFont">School Type</span>
                            
                            <v-select
                              v-model="school.type"
                              :items="['Private', 'Independent', 'Christian', 'State', 'Special Needs', 'VET']"
                              :rules="[rules.required]"
                              class="mt-3"
                              outlined
                            ></v-select>

                            <span :class="labelFont">Address</span>

                            <div class="mb-6 mt-3">
                              <vue-google-autocomplete
                                id="school-address"
                                ref="toAddress"
                                classname="address-field"
                                v-on:placechanged="getAddress"
                                placeholder="Search Address"
                                :fields="['address_components', 'geometry']"
                                country="au"
                              >
                              </vue-google-autocomplete>
                            </div>

                            <div v-if="school.address">
                              <v-text-field
                                :value="`${school.address.street_number || '' } ${school.address.route || ''}`"
                                label="Street Address"
                                readonly
                                outlined
                              ></v-text-field>

                              <v-row class="gap-10" no-gutters>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                    :value="school.address.locality || ''"
                                    label="Suburb"
                                    readonly
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    :value="school.address.administrative_area_level_1 || ''"
                                    label="State"
                                    readonly
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    :value="school.address.postal_code || ''"
                                    label="Postal Code"
                                    readonly
                                    outlined
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </div>

                            <span :class="labelFont">Locale</span>
                            
                            <v-select
                              v-model="school.locale"
                              :items="['Metro', 'Regional City', 'Regional Town', 'Remote']"
                              :rules="[rules.required]"
                              class="mt-3"
                              outlined
                            ></v-select>
                            
                            <span :class="labelFont">Website (optional)</span>
                            
                            <v-text-field
                              v-model="school.website"
                              :rules="[rules.url]"
                              class="mt-3"
                              outlined
                            ></v-text-field>
                            
                            <span :class="labelFont">About Our School (optional)</span>
                            
                            <v-textarea
                              v-model="school.introduction"
                              label="Include a short description about your school"
                              class="mt-4"
                              rows="10"
                              outlined
                            >
                            </v-textarea>
                        </v-form>
                        
                        <v-btn
                          @click="validateSchoolDetails()"
                          :loading="status.adding"
                          color="accent gradient"
                        >
                          Create Profile
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- End Title & Description Card -->
            
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

    <!-- DELETE DIALOG -->
    <confirm-delete
      :show="deleteLogoDialog"
      :deleting="status.deletingLogo"
      message="Are you sure you want to delete your school logo?"
      @cancel="deleteLogoDialog = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBs2BI8GCQdLMsm-0mLpNuGAGdSHjIknlg&libraries=places"></script>

<script>
import CreateSchoolSideBar from '@/components/dashboard/school/CreateSchoolSideBar'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import SchoolLogo from './SchoolLogo'
import { mapState, mapActions } from 'vuex'
import db from '@/firebase/init'
import rules from '@/rules'
import 'animate.css'

export default {
  name: 'School',

  metaInfo: {
    title: 'School Profile'
  },

  data() {
    return {
      rules,
      activeItem: 'overview',
      checkSection: [],
      isValid: false,
      deleteDialog: false,
      labelFont: 'heading-font default-size primary--text font-weight-bold',
      titleFont: 'text-h5 primary--text font-weight-bold',
      editFont: 'text-decoration-underline text-none caption ls-0 font-weight-regular',
      deleteLogoDialog: false,
    }
  },

  components: {
    VueGoogleAutocomplete,
    CreateSchoolSideBar,
    SchoolLogo
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.schools.status,
      school: state => state.schools.school,
    }),
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('schools', [
      'storeSchool',
      'deleteSchoolLogo'
    ]),

    required(value) {
      if (value instanceof Array && value.length == 0) {
        return 'Required.';
      }
      return !!value || 'Required.';
    },

    validateSchoolDetails() {
      if (this.$refs.overview.validate()) {
        Promise.all([this.storeSchool()])
        .then(() => {
            this.$store.commit('schools/reInitSchool')
            this.$router.push({ name: 'Schools' })
        })
      }
    },

    getAddress(addressData) {
      this.$store.commit('schools/setAddress', addressData)
      this.$refs.toAddress.$refs.autocomplete.value = ''
    },

    confirmDeleteLogo() {
      this.deleteLogoDialog = true
    },

    deleteConfirmed() {
      Promise.all([this.deleteSchoolLogo()])
      .then(() => this.deleteLogoDialog = false)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.$route.query.id) {
      db.collection('schools')
      .doc(this.$route.query.id)
      .get().then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.ref = doc.ref
          data.id = doc.id
          this.$store.commit('schools/setData', data)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">
  #talent-expertise .add-skill {
    will-change: box-shadow !important;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    padding: 16px;
    border-radius: 100%;
  }
</style>