<template>
  <div class="pl-4 pr-4">
    <v-card class="shadow-lg">
      <v-card-text>
        <div class="d-flex gap-12 align-center">
          <v-sheet class="flex-grow-1 pa-4 job-sidebar">
            <div
              class="create-job-sidebar"
              :class="active == 'overview' ? 'active' : ''"
            >
              <div class="d-flex flex-row justify-space-between">
                <span class="body-1 heading-font font-weight-bold">Overview</span>
                <v-icon ref="sidebarIconTD" left>
                  {{ active ? 'mdi-checkbox-marked-circle' : 'mdi-circle-outline' }}
                </v-icon>
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <div
              class="create-job-sidebar"
              :class="active == 'review' ? 'active' : ''"
            >
              <div class="d-flex flex-row">
                <span class="body-1 heading-font font-weight-bold">Review</span>
              </div>
            </div>
          </v-sheet>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  props: {
    active: Boolean,
    checkSection: Array,
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {},

  /* -------------------------------------------------------------------------- */
  /*                                    WATCH                                   */
  /* -------------------------------------------------------------------------- */
  watch: {
    checkSection: function (val) {
      if (val[0].section === "School Overview") {
        if (val[0].status === "empty") {
          this.$refs.sidebarIconTD.$el.classList.remove(
            "mdi-checkbox-marked-circle"
          )
          this.$refs.sidebarIconTD.$el.classList.add("mdi-circle-outline")
        } else {
          this.$refs.sidebarIconTD.$el.classList.remove("mdi-circle-outline")
          this.$refs.sidebarIconTD.$el.classList.add(
            "mdi-checkbox-marked-circle"
          )
        }
      } else if (val[0].section === "Contact Details") {
        if (val[0].status === "empty") {
          this.$refs.talentExpertise.$el.classList.remove(
            "mdi-checkbox-marked-circle"
          )
          this.$refs.talentExpertise.$el.classList.add("mdi-circle-outline")
        } else {
          this.$refs.talentExpertise.$el.classList.remove("mdi-circle-outline")
          this.$refs.talentExpertise.$el.classList.add(
            "mdi-checkbox-marked-circle"
          )
        }
      } else if (val[0].section === "School Goal") {
        if (val[0].status === "empty") {
          this.$refs.locationDetails.$el.classList.remove(
            "mdi-checkbox-marked-circle"
          )
          this.$refs.locationDetails.$el.classList.add("mdi-circle-outline")
        } else {
          this.$refs.locationDetails.$el.classList.remove("mdi-circle-outline")
          this.$refs.locationDetails.$el.classList.add(
            "mdi-checkbox-marked-circle"
          )
        }
      } else if (val[0].section === "Others") {
        if (val[0].status === "empty") {
          this.$refs.salary.$el.classList.remove("mdi-checkbox-marked-circle")
          this.$refs.salary.$el.classList.add("mdi-circle-outline")
        } else {
          this.$refs.salary.$el.classList.remove("mdi-circle-outline")
          this.$refs.salary.$el.classList.add("mdi-checkbox-marked-circle")
        }
      } else if (val[0].section === "All") {
        if (val[0].status === "empty") {
          console.log("clear all")
          this.$refs.sidebarIconTD.$el.classList.remove(
            "mdi-checkbox-marked-circle"
          )
          this.$refs.sidebarIconTD.$el.classList.add("mdi-circle-outline")

          this.$refs.talentExpertise.$el.classList.remove(
            "mdi-checkbox-marked-circle"
          )
          this.$refs.talentExpertise.$el.classList.add("mdi-circle-outline")

          this.$refs.locationDetails.$el.classList.remove(
            "mdi-checkbox-marked-circle"
          )
          this.$refs.locationDetails.$el.classList.add("mdi-circle-outline")

          this.$refs.salary.$el.classList.remove("mdi-checkbox-marked-circle")
          this.$refs.salary.$el.classList.add("mdi-circle-outline")
        }
      }
    },
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {},
}
</script>

<style scoped lang="scss">
.create-job-sidebar,
.create-job-sidebar span.body-1 {
  color: #d9d9d9 !important;
}

.create-job-sidebar.active,
.create-job-sidebar.active i,
.create-job-sidebar.active span {
  color: rgba(10,30,35,1) !important;
}

.create-job-sidebar i.mdi-checkbox-marked-circle {
  color: rgba(63,183,97,1) !important;
}


.create-job-sidebar.active:before {
  // border-left: 5px solid rgba(10,30,35,1);
  content: "";
  position: absolute;
  margin-top: 4px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  left: 0;
  height: 20px;
  width: 4px;
  background: rgba(10,30,35,1);
}
</style>